
.tit__head{
    font-size: 6.4rem;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tit__head{
        font-size: 10rem;
    }
}
@media (min-width:1024px){
    .tit{
        height: 88px;
        white-space: nowrap;
    }
    .tit__body{
        display: inline-flex;
        align-items: center;
        height: 52px;
    }
    .tit__head{
        font-size: 14rem;
        display: inline-flex;
        align-items: center;
        height: 88px;
    }
}
@media (min-width:1200px){
}

