
.main-section--check{
    position: relative;
    &__left{
        padding-top: var(--page-section-padding-y);
    }
    &__right{
        position: relative;
        z-index: 1;
        &__inner{
            width: 100%;
            padding: var(--page-section-padding-y) var(--container-gutter);
            background-image: url(/images/main/check-bg.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .main-section--check{
        position: relative;
        &__left{
            padding: var(--page-section-padding-y) 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: url(/images/main/check-bg2.svg);
            background-repeat: no-repeat;
            background-size: calc(50% - 80px) auto;
            background-position: left 40px bottom;
        }
        &__right{
            &__inner{
                padding: var(--page-section-padding-y);
            }
        }
    }
}

