
.story-item{
    width: 100%;
    box-sizing: border-box;
    &__stemp{
        background-position: center;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-image: url(/images/sub/story/story-stemp.sv);
    }
    &__hover{
        background-color: #fff;
        border-top: 1px solid var(--border-color);
    }
    &__title{
        min-height: 52px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .story-item{
        &__title{
            min-height: 64px;
        }
    }
}
@media (min-width:1024px){
    .story-item{
        &__contents{
            opacity: 1;
            visibility: visible;
            transition: .3s ease-out;
        }
        &__hover{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: rgba(0, 0, 0, .5); 
            border-top: 0;  
            opacity: 0;
            visibility: hidden;         
            transition: .3s ease-out;
            box-sizing: border-box;
            >*{   
                opacity: 0;
                visibility: hidden;         
                transform: scale(1.4);
                transition: .3s ease-out;
            }
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 5px solid var(--v-primary-base);
            top: 0;
            left: 0;
            transform: scaleY(0);
            opacity: 0;
            visibility: hidden;
            transition: .3s ease-out;
            box-sizing: border-box;
        }
        &:hover{
            .story-item__contents{
                opacity: 0;
                visibility: hidden;
            }
            .story-item__hover{
                opacity: 1;
                visibility: visible;
                >*{   
                    opacity: 1;
                    visibility: visible;         
                    transform: scale(1);
                    &:nth-child(1){
                        transition: .5s .1s ease-out;
                    }
                    &:nth-child(2){
                        transition: .5s .2s ease-out;
                    }
                    &:nth-child(3){
                        transition: .5s .3s ease-out;
                    }
                }
            }
            &::after{
                opacity: 1;
                visibility: visible;
                transform: scaleY(1);
                transition: .6s .3s ease-out;
            }
        }
        &__title{
            min-height: 0;
        }
    }
}
@media (min-width:1200px){
}

